import React,{useEffect,useState} from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../../components/Shared/Footer';
import HomeHeader from '../../Home/Home/HomeHeader/HomeHeader';
import DoctorDetailsArea from './DoctorDetailsArea/DoctorDetailsArea';
import { useParams } from 'react-router-dom';
import ApiService from '../../../services/ApiService';
import ModalCreate from '../../../components/Appointment/Modals/ModalCreate';

const DoctorDetails = () => {
    const { id, selectType } = useParams();


    const [dataDoctor, setDataDoctor] = useState();

    const [header, setHeader] = useState(false);
    const [modalShow, setModalShow] = useState(false);



   
    const handleModalShow = () => {
 
         setHeader(true);
         setModalShow(true);
    };


    const handleModalClose = () => {
    
      setHeader(false);
      setModalShow(false);
   };





    useEffect(() => {

        getDoctor();
       
      }, [])
    

    const getDoctor = () => {
        ApiService.get2Doctors(id)
          .then((response) => {
       
            setDataDoctor(response.data);         
            
          })
          .catch((e) => {
            console.log(e);
          });
      };

    return (
        <>
  

          {header?
          (null):
          (<HomeHeader/>)} 
            


            <CommonPageHeader title="Doctor Details" subtitle="Details" />
           
           {!dataDoctor?
           
           (null):(
           
             <>
             <DoctorDetailsArea dataDoctor={dataDoctor} handleModalShow={handleModalShow}/>
             
             <ModalCreate  dataDoctor={dataDoctor}  show={modalShow} onHide={handleModalClose} selectType={selectType}/>
             </>
             )
            }



          
            
            <Footer/>
        </>
    );
};

export default DoctorDetails;