import React from 'react';

import HomeHeader from '../Home/Home/HomeHeader/HomeHeader';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import CommonSectionArea from './CommonSectionArea3/CommonSectionArea';
const PrivacyNotice = () => {
   return (
      <>
      <HomeHeader/>
      <CommonPageHeader/>
      <CommonSectionArea/>

   
      </>
   );
};

export default PrivacyNotice;