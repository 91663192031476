import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import VideoPopup from '../../../components/VideoPopup/VideoPopup';
import useGlobalContext from '../../../hooks/useGlobalContext';
import ApiService from '../../../services/ApiService';
import { useNavigate } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-select';
import ModalListDoctors from "../../../components/ListDoctors/Modal";

<link rel="stylesheet" href="css/all.min.css"></link>

const HomeTwoHeroSection = () => {

   const [select, setSelect] = useState(0);
   const [selectType, setSelectType] = useState("kids");


   

   const [allCities, setAllCities] = useState([]);
   const [allServices, setAllServices] = useState([]);
   const [modalShow, setModalShow] =useState(false);

   const [allDoctors, setAllDoctors] = useState([]);

   const [selectedCity, setSelectedCity] = useState("");

   const [selectedService, setSelectedService] = useState();

   const [serviceOptionsArray, setServiceOptionsArray] = useState([]);
   

 



   useEffect(() => {
      getServicesfilter();
    
      getCities();
   
   }, [select, selectType, selectedCity ])

   const getCities = () => {

      ApiService.getCities(selectType)
        .then(response => {
         setAllCities(response.data);
         
        })
        .catch(e => {
          console.log(e);
        });
    };


    const getServicesfilter = () => {

      const params ={type:selectType, city:selectedCity}
    
      setServiceOptionsArray([]);
      ApiService.getServicesfilter(params)
        .then(response => {

         setAllServices(response.data.data);
        
        })
        .catch(e => {
          console.log(e);
        });
    };
  
  
   

   const cityOptions = allCities && allCities.map(city => (
      <option key={city} value={city}>
         {city}
      </option>
   ));

   useEffect(() => {
     
      const serviceOptions = allServices && allServices.map(service => (
         <option key={service.name} value={service.id}>
            {service.name}
         </option>
      ));
   
      setServiceOptionsArray(serviceOptions);
    }, [allServices])


   const handleCityChange = (event) => {

      
      setSelectedCity(event.target.value);
   }


   const handleServiceChange = (event) => {

      const numero = parseInt(event.target.value);

      setSelectedService(numero);
   }


   const handleClick =  () => {
      
      const params ={city:selectedCity, service:selectedService }
      

      ApiService.getDoctorsByServices(params)
      .then(response => {
       
       setAllDoctors(response.data.data);
       
      })
      .catch(e => {
        console.log(e);
      });


      setModalShow(true);
   }

   
   
  
   return (
      <>
         <VideoPopup videoId="oU_GUAWz52w" />
         <section className="hero-area">
            <div className="hero-slider hero_two_slider">
               <div className="slider-active">
                  <div className="single-slider slider-height slider-height-2 d-flex align-items-center"
                  data-background="img/slider/slider-bg-2.png">
                     <div className="container">
                        <div className="row align-items-center">
                           <div className="col-xl-6 col-lg-6 col-md-10">
                              <div className="hero-text hero-text-2 pt-35">
                                 <div className="hero-slider-caption hero-slider-caption-2">
                                    <h5 className="white-color">We are here for your care.</h5>
                                    <h1 className="white-color" >Your Specialist Dentist in Mexico.</h1>
                                 </div>
                                 <div className="hero-slider-btn">
                                    <a href="/#about-section" className="primary_btn btn-icon btn-icon-blue ml-0"><span>+</span>about us</a>
                                 {/*   <button onClick={() => setIsOpen(true)} className="play-btn popup-video"><i className="fas fa-play"></i></button>*/}
                                 </div>
                              </div>
                           </div>
                           <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-12 mt-10">
                              <div className="slider-right-2">
                                 <div className="caregive-box">
                                    <div className="search-form">
                                       <span className="sub-heading">We are here for you</span>
                                       <h3>Find A Dentist</h3>                                                               
                                    </div>                                  
                                    
                                    <div className="row">                                 
                                       <div className="col-xl-12">   

                                          {/* ON LINE /IN PERSON */}



                                          {/*
                                          <div className="row">
                                             <div className="col-xl-6 mb-4">
                                                <div className="switch-container">
                                                      <label className={`switch-label ${select === 0 ? 'selected' : ''}`} onClick={() => setSelect(0)} >In Person</label>
                                                      <input type="checkbox" className="switch-checkbox" />
                                                </div>
                                             </div>
                                             <div className="col-xl-6 mb-4">
                                                <div className="switch-container">
                                                      <label className={`switch-label ${select === 1 ? 'selected' : ''}`} onClick={() => setSelect(1)} >On Line</label>
                                                      <input type="checkbox" className="switch-checkbox" />
                                                </div>
                                             </div>
                                             
                                          </div>*/}

                                          {/* KIDS/ADULTS */}
                                          {allServices.length === 0 && (
                                             <div class="alert alert-danger" role="alert">
                                                There are no search results with the entered criteria !
                                             </div>
                                          )}
                                                                                 
                                          <div className="row">
                                             <div className="col-xl-6 mb-4">
                                                <div className="switch-container">
                                                      <label className={`switch-label ${selectType === "kids" ? 'selected' : ''}`} onClick={() => setSelectType("kids")}>Kids (under 17)</label>
                                                      <input type="checkbox" className="switch-checkbox" />
                                                </div>
                                             </div>
                                             <div className="col-xl-6 mb-4">
                                                <div className="switch-container">
                                                      <label className={`switch-label ${selectType === "adult" ? 'selected' : ''}`} onClick={() => setSelectType("adult")}>Adults (18+)</label>
                                                      <input type="checkbox" className="switch-checkbox"  />
                                                </div>
                                             </div>
                                          </div>


                                          <div className="appoinment-form-box appoinment-form-box-option d-flex mb-40">
                                             <div className="appoint-ment-icon">
                                                <img src="img/icon/cities.png" alt=""/>
                                             </div>
                                             <form className="appointment-form-2" action="#">
                                                <label htmlFor="input" className='label-title' >Cities</label>
                                                <select name="lc" id="lc" className="select_per"  onChange={handleCityChange}>
                                               
                                                   <option selected>Choose a City</option>
                                                   {cityOptions}
                                                </select>
                                             </form>
                                          </div>
                                                                              

                                          <div className="appoinment-form-box appoinment-form-box-option d-flex mb-40">
                                             <div className="appoint-ment-icon">
                                             <img src="img/icon/specialties.png" alt="" />
                                             </div>
                                             <form className="appointment-form-2" action="#">
                                                <label htmlFor="input" className='label-title'>Services</label>
                                                <select name="lc" id="lc" className="select_per" onChange={handleServiceChange}>
                                                <option selected>Choose a Service</option>
                                                   {serviceOptionsArray}
                                                </select>
                                             </form>
                                          </div>


                              

                                       </div>                                                                                                                             
                                       <div className="col-xl-12 mb-35">
                                          <div className="inner caregive-btn text-center">
                                             <button className="primary_btn gray-btn-border" onClick={()=>handleClick()} type="button"  >Search</button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <ModalListDoctors 
            show={modalShow} 
            setModalShow={setModalShow} 
            allDoctors={allDoctors} 
            cityOptions={cityOptions} 
            handleCityChange={handleCityChange}
            handleServiceChange={handleServiceChange}
            serviceOptions={serviceOptionsArray}
            selectedCity={selectedCity}
            selectType={selectType}
            setSelectType={setSelectType}
            selectedService={selectedService}
            setAllDoctors={setAllDoctors}
         />
      </>
   );
};
export default HomeTwoHeroSection;