import React from 'react';
import '../CommonSectionArea.css';

const CommonSectionArea = () => {
   return (
      <>
       <div className="bordered-div center-horizontal" style={{ width: '780px', margin: '0 auto' }}>
            <h4 className='center-izquierda'>
                AVISO DE PRIVACIDAD
            </h4>
            <p style={{ textAlign: 'justify' }}>
                Última actualización: 13 de mayo de 2024.<br/><br/>
                "PRA MED INTERNACIONAL", SOCIEDAD ANONIMA DE CAPITAL VARIABLE, mejor conocido como MTA Trust, con domicilio en calle xxxxx ### xxxxxx, xxx. c.p#####, México, 
                y portal de internet www.mtatrust.com, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
                <br/><br/>
                ¿Para qué fines utilizaremos sus datos personales?<br/><br/>
                Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:
            </p>

        
        
            {/* Lista fuera del párrafo */}
            <div style={{textAlign: 'justify'}}>
                <ul>
                    <li  className="con-padding">● Navegar, elegir y en su caso tramitar su cita médica con el especialista</li>
                    <li  className="con-padding">● Cualquier información relacionada con la cita médica con el especialista, entre ellas recordatorios, confirmación, agendar, temas de cobranza y pagos, y demás relacionados.</li>
                    <li  className="con-padding">● Enviar al especialista su información, así como su expediente clínico y médico.</li>
                </ul>
            </div>

            <br/>
            <p style={{ textAlign: 'justify' }}>

            De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:
            </p>

            <div style={{textAlign: 'justify'}}>
                <ul>
                    <li  className="con-padding">●	Hacerle llegar información médica, de tratamientos y de promociones</li>
                    <li  className="con-padding">● 	Enviar encuestas de satisfacción del servicio.</li>
                    <li  className="con-padding">●	Artículos de interés relacionados con los temas del portal.</li>
                </ul>
            </div>
            <br/><br/>
            <p style={{ textAlign: 'justify' }}>
            En caso de que no desee que sus datos personales se utilicen para estos fines secundarios, indíquelo a continuación:<br/><br/>

            No consiento que mis datos personales se utilicen para los siguientes fines:<br/><br/>

            [  ] Hacerle llegar información de promociones.<br/>
            [  ] Enviar encuestas de satisfacción.<br/>
            [  ] Hacer artículos de interés.<br/><br/>

            La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los 
            servicios y productos que solicita o contrata con nosotros.<br/><br/>
            
            ¿Qué datos personales utilizaremos para estos fines?<br/><br/>
            
            Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:

            </p>

            <div style={{textAlign: 'justify'}}>
                <ul>
                    <li  className="con-padding">●	Datos de identificación.</li>
                    <li  className="con-padding">●	Fecha de nacimiento.</li>
                    <li  className="con-padding">●	Datos de contacto (email, teléfono).</li>
                    <li  className="con-padding">●	Datos sobre características físicas.</li>

                </ul>
            </div>
            <br/><br/>
            <p style={{ textAlign: 'justify' }}>
              Además de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de privacidad utilizaremos los siguientes datos personales considerados como sensibles, 
              que requieren de especial protección:
            </p>
            <div style={{textAlign: 'justify'}}>
                <ul>
                    <li  className="con-padding">●	Estado de salud físico presente, pasado o futuro</li>
                    <li  className="con-padding">●	Historial clínico y médico</li>
                    <li  className="con-padding">●	Condiciones médicas actuales (por qué está buscando la cita).</li>
                    <li  className="con-padding">●	Resultados e interpretaciones de estudios médicos.</li>

                </ul>
            </div>
            <br/><br/>
            <p style={{ textAlign: 'justify' }}>
                
                ¿Con quién compartimos su información personal y para qué fines?<br/><br/>
                
                Le informamos que sus datos personales son compartidos dentro del país de la cita con las siguientes personas, empresas, 
                organizaciones o autoridades distintas a nosotros, para los siguientes fines:<br/><br/>
                
                Destinatario de los datos personales<br/><br/>	
 

            </p>

            <table style={{ border: '2px solid #000;' }}>
                    <tr>
                        <th>Destinatario de los datos personales</th>
                        <th>Finalidad</th>
                        <th>Requiere del consentimiento</th>
                    </tr>
                    <tr>
                        <td>Especialistas</td>
                        <td>Preparar los servicios dentales requeridos</td>
                        <td>No</td>
                    </tr>
            </table><br/><br/>
            <p style={{ textAlign: 'justify' }}>
                
                ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?<br/><br/>
                
                Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). 
                Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); 
                que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como 
                oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.<br/><br/>
                
                Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio:<br/><br/>
                
                team@mtatrust.com<br/><br/>
                
                Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, ponemos a su disposición el siguiente medio:<br/><br/>
                
                mtatrust.com<br/><br/>
                
                Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:<br/>
 
            </p>
            <div style={{textAlign: 'justify'}}>
                <ul style={{listStyleType: 'lower-alpha'}}>
                    <li className="con-padding">a)	Nombre de la persona o departamento de datos personales: Datos personales</li>
                    <li className="con-padding">b)	Domicilio: calle Roble 34, colonia Jurica, ciudad Querétaro, municipio o delegación Querétaro, c.p. 76100, en la entidad de Qro, país México</li>
                    <li className="con-padding">c)	Correo electrónico: team@mtatrust.com</li>
                    <li className="con-padding">d)	Número telefónico: 5546789956</li>
                </ul>
            </div>
            <br/><br/>
            <p style={{ textAlign: 'justify' }}>
                
                Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. 
                Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, 
                ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. 
                Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implica que no le podamos seguir prestando el servicio que nos solicitó, 
                o la conclusión de su relación con nosotros.<br/><br/>

                Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio:<br/><br/>

                team@mtatrust.com<br/><br/>

                Para conocer el procedimiento y requisitos para la revocación del consentimiento, ponemos a su disposición el siguiente medio:<br/><br/>

                team@mtatrust.com<br/><br/>
 
                ¿Cómo puede limitar el uso o divulgación de su información personal?<br/><br/>
 
                Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios:<br/><br/>

                team@mtatrust.com<br/><br/>

                El uso de tecnologías de rastreo en nuestro portal de internet<br/><br/>

                Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, 
                a través de las cuales es posible monitorear su comportamiento como usuario de internet, 
                así como brindarle un mejor servicio y experiencia al navegar en nuestra página. Los datos personales que recabamos a través de estas 
                tecnologías, los utilizaremos para los siguientes fines:<br/><br/>

                Prestar los servicios de citas dentales y envío de expedientes.<br/><br/>

                Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:<br/><br/>

            </p>

            <div style={{textAlign: 'justify'}}>
                <ul>                    
                    <li  className="con-padding">●	Identificadores, nombre de usuario y contraseñas de una sesión</li>
                    <li  className="con-padding">●	Idioma preferido por el usuario</li>
                    <li  className="con-padding">●	Región en la que se encuentra el usuario</li>
                    <li  className="con-padding">●	Tipo de navegador del usuario</li>
                    <li  className="con-padding">●	Tipo de sistema operativo del usuario</li>
                    <li  className="con-padding">●	Fecha y hora del inicio y final de una sesión de un usuario</li>
                    <li  className="con-padding">●	Páginas web visitadas por un usuario</li>
                    <li  className="con-padding">●	Búsquedas realizadas por un usuario</li>
                </ul>
            </div>
            <br/><br/>
            <p style={{ textAlign: 'justify' }}>
            ¿Cómo puede conocer los cambios en este aviso de privacidad?<br/><br/>
 
            El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; 
            de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro 
            modelo de negocio, o por otras causas.<br/><br/>
            
            Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: team@mtatrust.com.<br/><br/>
            
            El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente:<br/><br/>
            
            team@mtatrust.com<br/><br/>
            
            Su consentimiento para el tratamiento de sus datos personales<br/><br/>
            
            [  ] Consiento que mis datos personales sean tratados de conformidad con los términos y condiciones informados en el presente aviso de privacidad.<br/><br/>
 
            </p>


        

            

        </div>

        

      </>
   );
};

export default CommonSectionArea;
